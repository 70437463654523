import logger from 'utility/logger'

const BASE_KEY = 'proptax'

export const loadState = (key) => {
	const stateKey = BASE_KEY + key

	if (typeof window === 'undefined') return undefined

	const serializedState = localStorage.getItem(stateKey.toString())
	if (!serializedState || serializedState === 'undefined') return undefined

	logger.default(
		`Confirming load of state with key: ${stateKey}. Current loaded state: `,
		serializedState,
	)

	return JSON.parse(serializedState)
}

export const saveState = (key, state) => {
	const stateKey = BASE_KEY + key

	if (typeof window === 'undefined') return undefined

	const serializedState = JSON.stringify(state)
	localStorage.setItem(stateKey, serializedState)
	const savedState = loadState(stateKey.toString())
	logger.default(
		`Confirming save of state with key: ${stateKey}. Current saved state: `,
		JSON.stringify(savedState),
	)
}
