import axios from 'utility/axios'
import { GET_TENANT_SETTINGS, GET_TENANT_PROFILE } from 'utility/endpoints'

export const fetchSettings = async () => {
	const res = await axios.get(GET_TENANT_SETTINGS)
	return res?.data
}

export const fetchProfile = async () => {
	const res = await axios.get(GET_TENANT_PROFILE)
	return res?.data
}
