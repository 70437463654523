import Custom404 from 'pages/404'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { useTenantSettings } from 'hooks/api/tenant/useTenantSettings'
import { SkeletonPage } from 'components/common/skeletons/SkeletonPage'

export const TenantWrapper = ({ children }) => {
	const { data: tenantSettings, isLoading: tenantSettingsLoading } = useTenantSettings()
	const { data: tenantProfile, isLoading: tenantProfileLoading } = useTenantProfile()

	if (!children) return null

	if ((!tenantSettings && tenantSettingsLoading) || (!tenantProfile && tenantProfileLoading))
		return <SkeletonPage />

	if (!tenantSettings || !tenantProfile) {
		return <Custom404 />
	}

	return children
}
