export const formatUserObject = (user) => {
	const groupAccounts = user?.groupAccounts
		? user?.groupAccounts.map((acct) => {
				return {
					...acct,
					link: `/users/${user.userSlugId}/groups/${acct.slugId}`,
				}
		  })
		: null

	const partnerAccounts = user?.partnerAccounts
		? user?.partnerAccounts.map((acct) => {
				return {
					...acct,
					link: `/users/${user.userSlugId}/partners/${acct.slug}/portal/${acct.slugId}`,
				}
		  })
		: null

	const primaryGroupAccount = groupAccounts && groupAccounts.length ? groupAccounts[0] : null
	const primaryPartnerAccount =
		partnerAccounts && partnerAccounts.length ? partnerAccounts[0] : null

	const isHomeowner = groupAccounts && !!groupAccounts.length
	const isPartner = user?.partnerAccounts && !!user?.partnerAccounts.length

	const homeownerSlug = isHomeowner
		? `/users/${user?.userSlugId}` //`/users/${user?.userSlugId}/groups/${primaryGroupAccount.slugId}`
		: ''

	const email = user?.groupEmail

	const partnerSlug = isPartner ? partnerAccounts[0].link : ''
	const baseSlug = homeownerSlug ? homeownerSlug : partnerSlug

	const isBlocked = user?.isBlocked === 'Yes' ? true : false
	const isAdmin = user?.isAdmin === 'Yes' ? true : false

	return {
		...user,
		isAdmin,
		isBlocked,
		email,
		baseSlug,
		partnerSlug,
		homeownerSlug,
		groupAccounts,
		partnerAccounts,
		primaryGroupAccount,
		primaryPartnerAccount,
	}
}

export const dedupedStringArray = (array) => {
	return array.filter((item, index) => array.indexOf(item) === index)
}

export const dedupedPids = (pids) => {
	return pids.filter((pid, index) => {
		return index === pids.findIndex((obj) => obj.pid === pid.pid)
	})
}
