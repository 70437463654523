import React from 'react'
import Loader from 'react-loader-spinner'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const Spinner = ({ type = 'Oval', height = 20, width = 20, size, style = {}, ...props }) => {
	if (size) {
		width = size
		height = size
	}
	const { data: tenantProfile } = useTenantProfile()
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<div style={{ display: 'inline-block', ...style }}>
			<Loader type={type} color={secondaryColor} height={height} width={width} {...props} />
		</div>
	)
}

export default Spinner
