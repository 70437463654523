import { WORDPRESS_API_BASE } from 'utility/endpoints'
const query = function (options) {
	const _query = Object.keys(options)
		.map((elm) => {
			return `${elm}=${options[elm]}`
		})
		.join('&')
		.replace(/^/, '?')

	return _query.length > 2 ? _query : ''
}

query.toJson = function (search) {
	const pairs = search.slice(1).split('&')
	const result = {}

	pairs.forEach((pair) => {
		pair = pair.split('=')
		result[pair[0]] = decodeURIComponent(pair[1] || '')
	})

	return JSON.parse(JSON.stringify(result))
}

export const constructWPURL = (endpointBase, params) => {
	let url = `${WORDPRESS_API_BASE}${endpointBase}`
	if (!params) return url

	url = `
    ${url}?
    ${params.embed ? `_embed&` : ''}
    ${params.slug ? `slug=${params.slug}&` : ''}
    ${params.per_page ? `per_page=${params.per_page}&` : ''}
    ${params.page ? `page=${params.page}&` : ''}
    ${params.include ? `include=${params.include}&` : ''}
    ${params.filterValue && params.filterName ? `${params.filterName}=${params.filterValue}&` : ''}
    ${params.consumer_key ? `consumer_key=${params.consumer_key}&` : ''}
    ${params.consumer_secret ? `consumer_secret=${params.consumer_secret}` : ''}
  `
	return url.replace(/\s/g, '')
}
export default query
