import axios from 'utility/axios'
import {
	GET_PARTNER_STATS,
	GET_PARTNER_PROPERTIES,
	GET_GRID_PARTNER_FROM_SLUG,
	GET_PARTNER_FROM_SLUG,
	GET_PARTNER_PUBLIC_INFO,
	GET_PARTNER_FROM_ID,
	GET_PARTNER_COMMMISSION_TYPES,
	GET_PARTNER_INDUSTRY_TYPES,
	GET_PARTNER_INTERNAL_MARKETS,
} from 'utility/endpoints'
import getNested from 'utility/getNested'
import { formatPropertyMetaStatus, formatPropertySignupStatus } from 'utility/property'

export const fetchPublicPartner = async ({ slug }) => {
	if (!slug || slug === '[slug]') return

	try {
		const res = await axios.get(`${GET_PARTNER_PUBLIC_INFO}${slug}`)

		if (res?.data?.stripeCouponJson)
			res.data.stripeCouponJson = JSON.parse(res?.data?.stripeCouponJson)

		if (res?.data?.stripePlanJson) res.data.stripePlanJson = JSON.parse(res?.data?.stripePlanJson)

		return { public: res.data }
	} catch (err) {
		console.log('Error: ', err)
		return err
	}
}

export const fetchPartner = async ({ slug, id }) => {
	if ((!slug && !id) || id === '[id]' || slug === '[slug]') return null

	try {
		let res
		if (slug) res = await axios.get(`${GET_PARTNER_FROM_SLUG}${slug}`)
		else if (id) res = await axios.get(`${GET_PARTNER_FROM_ID}${id}`)

		if (getNested(res, 'data', 'stripeCouponJson'))
			res.data.stripeCouponJson = JSON.parse(getNested(res, 'data', 'stripeCouponJson'))

		if (getNested(res, 'data', 'stripePlanJson'))
			res.data.stripePlanJson = JSON.parse(getNested(res, 'data', 'stripePlanJson'))

		return res.data
	} catch (err) {
		console.log('Error: ', err)
	}
}

export const fetchGridPartnerBySlug = async ({ slug }) => {
	if (!slug || slug === '[slug]') return null

	try {
		const res = await axios.get(`${GET_GRID_PARTNER_FROM_SLUG}${slug}`)
		return res.data
	} catch (err) {
		console.log('Error: ', err)
	}
}

export const fetchPartnerPropertiesByPartnerId = async ({ partnerId, year }) => {
	if (!partnerId || partnerId === '[partnerId]') return null

	try {
		let url = year
			? `${GET_PARTNER_PROPERTIES}/${partnerId}/${year}`
			: `${GET_PARTNER_PROPERTIES}/${partnerId}`
		const res = await axios.get(url)
		const partnerPortfolioData = getNested(res, 'data')
		return {
			...partnerPortfolioData,
			properties:
				partnerPortfolioData &&
				partnerPortfolioData.properties &&
				partnerPortfolioData.properties.length
					? partnerPortfolioData.properties.map((property) => {
							return {
								id: getNested(property, 'propertyTag'),
								htsGroup: property,
								meta: {
									signupState: formatPropertySignupStatus({ data: property }),
									status: formatPropertyMetaStatus({ data: property }),
								},
							}
					  })
					: [],
		}
	} catch (err) {
		console.log('Error: ', err)
	}
}

export const fetchPartnerStatsByPartnerId = async ({ partnerId, year }) => {
	if (!partnerId || partnerId === '[partnerId]') return null

	try {
		let url = year
			? `${GET_PARTNER_STATS}/${partnerId}/${year}`
			: `${GET_PARTNER_STATS}/${partnerId}`
		const res = await axios.get(url)
		return res.data
	} catch (err) {
		console.log('Error: ', err)
	}
}

export const fetchPartnerComissionTypes = async ({ partnerSlugId }) => {
	const path = GET_PARTNER_COMMMISSION_TYPES({
		partnerSlugId,
	})
	const res = await axios.get(path)
	return res.data
}

export const fetchPartnerIndustryTypes = async () => {
	const res = await axios.get(GET_PARTNER_INDUSTRY_TYPES)
	return res.data
}

export const fetchPartnerInternalMarkets = async () => {
	const res = await axios.get(GET_PARTNER_INTERNAL_MARKETS)
	return res.data
}
