import { useQuery } from '@tanstack/react-query'
import { fetchProfile } from 'utility/queries/tenant'

export const useTenantProfile = () => {
	return useQuery({
		enabled: true,
		queryKey: ['tenantProfile'],
		queryFn: () => fetchProfile(),
	})
}
