import React, { useContext, useEffect, useMemo } from 'react'
import { defaultObjData } from 'utility/useStateDefaults'
import axios from 'utility/axios'
import { GET_AUTHTOKEN, GET_USER_ME } from 'utility/endpoints'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useApp } from 'components/providers/AppProvider'
import { formatUserObject } from 'utility/object'
import { useRouter } from 'next/router'

const GroupContext = React.createContext(null)

const SelfProvider = ({ children }) => {
	const router = useRouter()

	const { authToken, setAuthToken } = useApp()
	const [selfUser, setSelfUser, isSelfUserHydrated] = useLocalStorage('selfUser', defaultObjData)

	useEffect(() => {
		if (!authToken && selfUser && Object.keys(selfUser?.data).length) {
			logOut()
		}
	}, [selfUser, authToken])

	useEffect(() => {
		if (authToken) {
			fetchSelfFromAuthToken(authToken)
		}
	}, [fetchSelfFromAuthToken, authToken])

	const loginFromToken = async (loginToken) => {
		try {
			let response = await axios.get(`${GET_AUTHTOKEN}${loginToken}`)
			setAuthToken(response?.data)
			return response
		} catch (err) {
			// Pass the error to the response
			throw err
		}
	}

	const fetchSelfFromAuthToken = async (intAuthToken) => {
		let headers = {}
		if (intAuthToken) {
			headers = {
				'Auth-Token': intAuthToken,
			}
		}

		try {
			setSelfUser({ ...selfUser, loading: true })
			let response = await axios.get(GET_USER_ME, {
				headers,
			})
			// Set the selfUser here...
			setSelfUser({ ...selfUser, data: response.data })
			return response.data
		} catch (err) {
			setSelfUser({ ...selfUser, error: err, loading: false })
			setAuthToken('')
			// Pass the error to the response
			throw err
		}
	}

	// Set authToken to '', then watch and log selfUser out
	const logOut = () => {
		setAuthToken('')
		router.push('/auth/signin')
	}

	useEffect(() => {
		if (!authToken) {
			setSelfUser(defaultObjData)
		}
	}, [authToken])

	const isAdmin = selfUser?.data?.isAdmin === 'Yes' ? true : false

	const isSignedIn = authToken && selfUser && Object.keys(selfUser?.data).length > 0 ? true : false

	const isBlocked = selfUser?.data?.isBlocked === 'Yes' ? true : false

	const selfValue = formatUserObject({ ...selfUser.data, isSignedIn })

	const isHomeowner = selfValue?.groupAccounts && selfValue?.groupAccounts.length > 0
	const isPartner = selfValue?.partnerAccounts && selfValue?.partnerAccounts.length > 0

	if (!isSelfUserHydrated) return null

	return (
		<GroupContext.Provider
			value={{
				loginFromToken,
				fetchSelfFromAuthToken,
				logOut,
				isSignedIn,
				user: selfValue,
				isAdmin,
				isHomeowner,
				isBlocked,
				isPartner,
				authToken,
			}}
		>
			{children}
		</GroupContext.Provider>
	)
}

const useSelf = () => {
	const auth = useContext(GroupContext)
	if (auth == null) {
		throw new Error('useSelf() called outside of a SelfProvider?')
	}
	return auth
}

export { SelfProvider, useSelf }
