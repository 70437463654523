import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
	font-family: 'Rubik', sans-serif;
	font-size: ${(props) => `${props.size}px`};
`

const Title = ({ children, align = 'left', large, size = 28, style }) => {
	if (large) size = 34
	return (
		<H1 size={size} style={{ marginBottom: 5, textAlign: align, ...style }}>
			{children}
		</H1>
	)
}

export default Title
