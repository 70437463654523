export const COLORS = {
	WHITE: '#ffffff',
	PRIMARY: '#000000', //214A58 - 366978
	ACCENT: '#2c2c2c',
	STEPS_COLOR: '#000000',
	PRIMARY_DARK: '#000000',
	BACKGROUND: '#FFFFFF',
	GRAY: '#2C2C2C',
	DEFAULT_STEP_COLOR: '#ffffff',
	OFF_WHITE: '#F9F9F9',
	PLACEHOLDER: '#757575',
	LIGHT: '#A3A3A3',
	DISABLED: '#CFCFCF',
	LIGHT_BACKGROUND: '#F2F2F2',
	MUTED_GRAY: '#F9F9F9',
	GROUP_GRAY: '#FCFCFC',
	SIDEBAR_TEXT_GRAY: '#828282',
	SIDEBAR_GRAY: '#F9F9F9',
	SKELETON: '#EFEFEF',
	LIGHT_GRAY: '#F7FAF7',
	RED: '#ff0000',
	FOOTER: '#2C2C2C',
	COPYFOOTER: '#000000',
	WEDGE: '#F8F8F8',
	GREEN: '#6EA964',
	YELLOW: '#EDE99E',
	BLACK: '#000000',

	OFF_GREEN: '#aed8dd',
	DARK_BLUE: '#23424A',
	BLUE: '#347287',
	BRIGHT_BLUE: '#2D8F98',
	MEDIUM_BLUE: '#AEC6CF',
	LIGHT_BLUE: '#f3f7f9',
	ALT_LIGHT_BLUE: '#F2F9FC',

	LIGHT_GREEN: '#F7FAF7',
	MODAL_BACKGROUND: '#00000099',
	TRANSPARENT: '#00000000',
}

export default COLORS
