import axios from 'axios'
import { subdomain } from 'utility/domain'
import { loadState } from 'utility/localStorage'

let baseURL = '/'
const Axios = axios.create({
	baseURL,
	timeout: 100000,
	crossdomain: true,
	withCredentials: false,
})

Axios.interceptors.request.use((config) => {
	const authToken = loadState('authToken')
	if (authToken) {
		config.headers['Auth-Token'] = authToken
	}

	if (subdomain) {
		config.headers['Tenant-Code'] = subdomain
	}

	return config
})

export default Axios
