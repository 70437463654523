import { useQuery } from '@tanstack/react-query'
import { fetchSettings } from 'utility/queries/tenant'

export const useTenantSettings = () => {
	return useQuery({
		enabled: true,
		queryKey: ['tenantSettings'],
		queryFn: () => fetchSettings(),
	})
}
